import Vue from "vue";
import axios from "axios";
import {Message} from "element-ui";
// 第三方插件
import "element-ui/lib/theme-chalk/index.css";

Vue.prototype.$axios = axios;
// 字体图标
const service = axios.create({
    baseURL: Vue.prototype.BASE_URL,
    timeout: 50000
});
service.defaults.withCredentials = true; // 让ajax携带cookie

service.interceptors.request.use(config => {
        console.log("请求地址：", config.baseURL + config.url)
        // 是否需要设置 token
        const isToken = (config.headers || {}).isToken === false
        let token = sessionStorage.getItem("token")
        if (token && !isToken) {
            config.headers['Authorization'] = token // 让每个请求携带自定义token 请根据实际情况自行修改
        }
        config.url = config.url + "?token=" + token;
        const locale = localStorage.getItem('locale');
        if (locale === 'zh') {
            config.url = config.url + "&lang=zh_CN";
        } else if (locale === "en") {
            config.url = config.url + "&lang=en_US";
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

// 响应前拦截
service.interceptors.response.use(response => {
        const res = response.data
        if (res.code !== 200) {
            Message({
                message: res.message || '服务端异常',
                type: 'error',
                duration: 5 * 1000
            })
            return Promise.reject(new Error(res.message || '服务端异常'))
        } else {
            return res
        }
    },
    error => {
        console.log('err' + error) // for debug
        Message({
            message: error.message,
            type: 'error',
            duration: 5 * 1000
        })
        return Promise.reject(error)
    }
);

export default service;
